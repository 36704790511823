import type {CodeSection} from '@github-ui/code-nav'
import type {SafeHTMLString} from '@github-ui/safe-html'
import {useCodeViewOptions} from '@github-ui/use-code-view-options'
import {Box} from '@primer/react'
import React, {useEffect, useImperativeHandle, useRef} from 'react'

import {useDeferredAST} from '../../../../contexts/DeferredASTContext'
import {useIsCursorEnabled} from '../../../../hooks/use-cursor-navigation'
import type {SetStickyLinesType} from '../../../../hooks/use-sticky-lines'
import {isLineInViewport, queryMatchElement, useShouldUseInert} from '../../../../utilities/lines'
import SelectAllShortcutButton from '../../../../utilities/SelectAllShortcutButton'
import type {CodeNavData} from '../BlobContent'
import type {CodeLinesHandle} from './code-lines-handle'
import {CodeFoldingEllipsisOverlay} from './CodeFoldingEllipsisOverlay'
import {CodeLine} from './CodeLine'
import type {CodeLineData} from './hooks/use-code-lines'
import {useVirtualCodeBlob} from './hooks/use-virtual-code-blob'
import {LineNumber} from './LineNumber'

export interface CodeLinesProps {
  linesData: CodeLineData[]
  tabSize: number
  onCollapseToggle: () => void
  nonTruncatedLinesData?: CodeLineData[]
  materializeAllLines?: boolean
  colorizedLines?: SafeHTMLString[] | null
  textOverlayShouldBeVisible?: boolean
  codeSections?: Map<number, CodeSection[]>
  codeLineToSectionMap?: Map<number, CodeSection[]>
  contentWidth?: number
  textAreaRef?: React.RefObject<HTMLTextAreaElement>
  cursorContainerRef?: React.RefObject<HTMLDivElement>
  isTextAreaFocused?: boolean
  textSelection?: {start: number; end: number; keyboard: boolean; displayStart: boolean}
  additionalTextAreaInstructions?: string
  copilotAccessAllowed: boolean
  onLineNumberClick?: React.MouseEventHandler<HTMLDivElement>
  onLineStickOrUnstick?: SetStickyLinesType
  onCodeNavTokenSelected?: (symbol: CodeNavData) => void
}

export const CodeLines = React.memo(React.forwardRef(CodeLinesUnmemoized))

function CodeLinesUnmemoized(
  {
    linesData,
    onLineNumberClick,
    codeSections,
    codeLineToSectionMap,
    onLineStickOrUnstick,
    tabSize,
    contentWidth,
    onCollapseToggle,
    cursorContainerRef,
    textAreaRef,
    materializeAllLines,
    copilotAccessAllowed,
  }: CodeLinesProps,
  ref: React.ForwardedRef<CodeLinesHandle>,
) {
  const parentRef = useRef<HTMLTableElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const horizontalScrollBarRef = useRef<HTMLDivElement>(null)
  const shouldUseCursor = useIsCursorEnabled()
  // Prevent loops and jumping when scrolling
  const setNextTextAreaScroll = useRef(true)
  const setNextScrollBarScroll = useRef(true)
  const setNextScrollContainerScroll = useRef(true)
  const {stylingDirectives} = useDeferredAST()

  useEffect(() => {
    //need to set the on scroll here because it relies on having access to parentRef to sync up the scrolls
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.onscroll = () => {
        if (scrollContainerRef.current && textAreaRef?.current) {
          if (!setNextScrollContainerScroll.current) {
            setNextScrollContainerScroll.current = true
            return
          }

          if (textAreaRef.current.scrollLeft === scrollContainerRef.current.scrollLeft) return

          // Goes in a loop textArea -> contianer -> scrollBar -> textArea , so we need to prevent the next scroll on the textArea
          setNextTextAreaScroll.current = !(setNextScrollBarScroll.current && setNextScrollContainerScroll.current)
          scrollContainerRef.current.scrollLeft = textAreaRef.current.scrollLeft
        }
        if (cursorContainerRef && cursorContainerRef.current && textAreaRef?.current) {
          cursorContainerRef.current.scrollLeft = textAreaRef.current.scrollLeft
        }
      }
      const textRef = textAreaRef.current
      return () => {
        if (textRef) {
          textRef.onscroll = null
        }
      }
    }
  }, [textAreaRef, parentRef, shouldUseCursor, cursorContainerRef])

  const wrapOptionEnabled = useCodeViewOptions().codeWrappingOption.enabled
  const shouldUseInert = useShouldUseInert()

  const virtualizer = useVirtualCodeBlob({
    parentRef,
    lineCount: linesData.length,
    materializeAllLines: !!materializeAllLines,
  })

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (isLineInViewport(0)) return
      virtualizer.scrollToIndex(0, {align: 'start'})
    },
    scrollToLine: (lineNumber, column) => {
      //align: start because this positions the element closer to the top of the screen
      virtualizer.scrollToIndex(lineNumber, {align: 'start'})

      const container = parentRef.current
      if (!container) {
        return
      }

      container.scroll({left: getHorizontalScrollOffset(container, lineNumber, column)})
    },
  }))

  const scrollingProps = shouldUseCursor
    ? {overflowX: 'overlay', scrollbarWidth: 'none', '&::-webkit-scrollbar': {display: 'none'}}
    : {overflowX: 'auto'}

  return (
    <Box
      ref={parentRef}
      sx={{pointerEvents: shouldUseCursor ? 'none' : 'auto'}}
      onScroll={event => handleBlobScrollSync(event, textAreaRef)}
    >
      <Box
        ref={scrollContainerRef}
        sx={scrollingProps}
        //TODO: this is necessary to resolve an axe complaint, but we don't actually want this to be in the tab order.
        //unsure what the resolution to that issue is outside of an onFocus
        tabIndex={0}
        onScroll={() => {
          if (shouldUseCursor && scrollContainerRef.current && horizontalScrollBarRef.current) {
            if (!setNextScrollBarScroll.current) {
              setNextScrollBarScroll.current = true
              return
            }

            if (horizontalScrollBarRef.current.scrollLeft === scrollContainerRef.current.scrollLeft) return
            // Goes in a loop contianer -> scrollBar -> textArea -> container, so we need to prevent the next scroll on the container
            setNextScrollContainerScroll.current = !(setNextScrollBarScroll.current && setNextTextAreaScroll.current)
            horizontalScrollBarRef.current.scrollLeft = scrollContainerRef.current.scrollLeft
          }
        }}
      >
        <Box
          className="react-code-file-contents"
          role="presentation"
          aria-hidden={true}
          data-tab-size={tabSize}
          data-testid={'code-lines-container'}
          data-paste-markdown-skip
          sx={{
            tabSize,
            position: 'relative',
            width: contentWidth,
            maxWidth: wrapOptionEnabled ? '100%' : 'unset',
          }}
          style={{height: virtualizer.totalSize}}
          data-hpc
        >
          <div
            className="react-line-numbers"
            style={{
              pointerEvents: 'auto',
              height: virtualizer.totalSize,
              position: 'relative',
              zIndex: 2,
            }}
          >
            {virtualizer.virtualItems.map(virtualItem => {
              const lineData = linesData[virtualItem.index]!
              return (
                <LineNumber
                  codeLineData={lineData}
                  key={`line-number-${lineData.lineNumber}-content:${lineData.rawText?.substring(0, 100)}`}
                  onClick={onLineNumberClick}
                  ownedCodeSections={codeSections}
                  onLineStickOrUnstick={onLineStickOrUnstick}
                  onCollapseToggle={onCollapseToggle}
                  virtualOffset={virtualItem.start}
                  copilotAccessAllowed={copilotAccessAllowed}
                />
              )
            })}
          </div>
          <div className="react-code-lines" style={{height: virtualizer.totalSize}}>
            {virtualizer.virtualItems.map(virtualItem => {
              const lineData = linesData[virtualItem.index]!
              return (
                <CodeLine
                  codeLineData={lineData}
                  stylingDirectivesLine={
                    lineData.stylingDirectivesLine ??
                    (stylingDirectives ? stylingDirectives[lineData.lineNumber - 1] : undefined)
                  }
                  shouldUseInert={shouldUseInert}
                  codeLineClassName={lineData.codeLineClassName}
                  key={`line-number-${lineData.lineNumber}-content:${lineData.rawText?.substring(0, 100)}`}
                  id={`LC${lineData.lineNumber}`}
                  onLineStickOrUnstick={onLineStickOrUnstick}
                  setIsCollapsed={onCollapseToggle}
                  codeLineToSectionMap={codeLineToSectionMap}
                  virtualOffset={virtualItem.start}
                  virtualKey={virtualItem.key}
                  // Not great but measureRef doesn't work in tests due to something wrong in the observer
                  // In order to make tests work, we need to not pass this prop during tests
                  measureRef={process.env.NODE_ENV === 'test' ? undefined : virtualItem.measureRef}
                  copilotAccessAllowed={copilotAccessAllowed}
                />
              )
            })}
          </div>
          <SelectAllShortcutButton
            shouldNotOverrideCopy={shouldUseCursor}
            containerRef={shouldUseCursor ? textAreaRef : parentRef}
          />
          {!wrapOptionEnabled && (
            <CodeFoldingEllipsisOverlay
              linesData={linesData}
              onLineStickOrUnstick={onLineStickOrUnstick}
              setIsCollapsed={onCollapseToggle}
              tabSize={tabSize}
              extraLeftPadding={82}
            />
          )}
        </Box>
      </Box>
      {shouldUseCursor &&
        contentWidth &&
        scrollContainerRef.current &&
        scrollContainerRef.current.clientWidth < contentWidth && (
          <Box
            sx={{
              width: '100%',
              pointerEvents: 'auto',
              overflowX: 'auto',
              overflowY: 'hidden',
              height: '17px',
              position: 'sticky',
              bottom: 0,
              zIndex: 2,
            }}
            onScroll={() => {
              if (horizontalScrollBarRef.current && textAreaRef?.current) {
                if (!setNextTextAreaScroll.current) {
                  setNextTextAreaScroll.current = true
                  return
                }

                if (horizontalScrollBarRef.current.scrollLeft === textAreaRef.current.scrollLeft) return

                // Goes in a loop scrollBar -> textArea -> container -> scrollBar, so we need to prevent the next scroll on the scrollBar
                setNextScrollBarScroll.current = !(
                  setNextScrollContainerScroll.current && setNextTextAreaScroll.current
                )
                textAreaRef.current.scrollLeft = horizontalScrollBarRef.current.scrollLeft
              }
            }}
            ref={horizontalScrollBarRef}
            // Prevent cursor events
            onClick={ev => ev.preventDefault()}
            onMouseDown={ev => ev.preventDefault()}
            onMouseUp={ev => ev.preventDefault()}
          >
            <Box sx={{width: contentWidth, height: '1px'}} />
          </Box>
        )}
    </Box>
  )
}

//becasue we are relying on the text area's highlighting to show the highlights, we need
//to sync the horizontal scrolling of the visible blob and the invisible text area
export function handleBlobScrollSync(
  event: React.UIEvent<HTMLDivElement>,
  textAreaRef: React.RefObject<HTMLTextAreaElement> | undefined,
) {
  const scrolledElement = event.target as HTMLElement
  textAreaRef?.current?.scrollTo(scrolledElement.scrollLeft, scrolledElement.scrollTop)
}

export function getHorizontalScrollOffset(container: HTMLElement, lineNumber: number, column?: number): number {
  if (!column) {
    return 0
  }
  const element = queryMatchElement(lineNumber, column)
  if (!element) {
    return 0
  }

  const containerRect = container.getBoundingClientRect()
  const elementRect = element.getBoundingClientRect()
  const fitsWithoutScroll =
    containerRect.left + containerRect.width - container.scrollLeft - (elementRect.left + elementRect.width) > 0

  // Prefer 0 offset if element fits to avoid annoying micro scrolling
  return fitsWithoutScroll ? 0 : element.offsetLeft
}

try{ CodeLines.displayName ||= 'CodeLines' } catch {}
try{ CodeLinesUnmemoized.displayName ||= 'CodeLinesUnmemoized' } catch {}