import type {CodeSection} from '@github-ui/code-nav'
import {noop} from '@github-ui/noop'
import {Box} from '@primer/react'
import type {BetterSystemStyleObject} from '@primer/react/lib-esm/sx'

import {useCodeLineIntersectionObservers} from '../../../../hooks/use-code-line-observer'
import {useCurrentLineHeight} from '../../../../hooks/use-current-line-height'
import {useStickyHeaderHeight} from '../../../../hooks/use-sticky-header-height'
import type {SetStickyLinesType} from '../../../../hooks/use-sticky-lines'
import {getActualLineNumberBinarySearch} from '../../../../utilities/lines'
import type {CodeLineData} from './hooks/use-code-lines'

export function StickyLineObserverOverlay({
  linesData,
  onLineStickOrUnstick,
  codeLineToSectionMap,
}: {
  linesData: CodeLineData[]
  onLineStickOrUnstick?: SetStickyLinesType
  codeLineToSectionMap?: Map<number, CodeSection[]>
}) {
  const stickyHeaderHeight = useStickyHeaderHeight()
  const lineHeight = useCurrentLineHeight('react-line-numbers')
  if (codeLineToSectionMap === undefined) return null

  return (
    <>
      {linesData.map(lineData => {
        const lineNumber = lineData.lineNumber
        const numParents = codeLineToSectionMap?.get(lineNumber)?.length ?? 0
        if (lineNumber === undefined || lineData.ownedSection === undefined) return null
        const actualLineNumber = getActualLineNumberBinarySearch(lineNumber, linesData)

        return (
          <StickyLineObserverLine
            key={`observer-overlay-${actualLineNumber}-lineNumber-no-virtualization`}
            className="symbol-highlight react-code-text"
            lineData={linesData[actualLineNumber]!}
            stickyHeaderHeight={stickyHeaderHeight}
            numParents={numParents}
            onLineStickOrUnstick={onLineStickOrUnstick ?? noop}
            sx={{
              position: 'absolute',
              top: lineHeight * (actualLineNumber - 1),
              pl: '10px',
              height: lineHeight,
              whiteSpace: 'pre',
            }}
          />
        )
      })}
    </>
  )
}

function StickyLineObserverLine({
  lineData,
  onLineStickOrUnstick,
  numParents,
  stickyHeaderHeight,
  className,
  sx,
}: {
  lineData: CodeLineData
  onLineStickOrUnstick: SetStickyLinesType
  numParents: number
  stickyHeaderHeight: number
  className: string
  sx: BetterSystemStyleObject
}) {
  const lineRefCallbackForObserver = useCodeLineIntersectionObservers(
    lineData,
    true,
    stickyHeaderHeight,
    onLineStickOrUnstick,
    numParents,
  )
  return (
    <Box
      ref={ref => {
        lineRefCallbackForObserver(ref as HTMLTableRowElement | null)
      }}
      className={className}
      sx={{
        mb: '-20px',
        color: 'transparent',
        position: 'absolute',
        maxHeight: '6rem',
        overflow: 'hidden',
        width: '100%',
        display: 'inline-block',
        userSelect: 'none',
        ...sx,
      }}
      data-testid={'sticky-line-observer'}
    />
  )
}

try{ StickyLineObserverOverlay.displayName ||= 'StickyLineObserverOverlay' } catch {}
try{ StickyLineObserverLine.displayName ||= 'StickyLineObserverLine' } catch {}