import {useLayoutEffect} from '@github-ui/use-layout-effect'
import {Box} from '@primer/react'
import {useRef, useState} from 'react'

import {useCurrentBlob} from '../../../../hooks/CurrentBlob'
import {useCurrentLineHeight} from '../../../../hooks/use-current-line-height'
import {useIsSafari} from '../../../../hooks/use-is-safari'
import type {HighlightPosition} from '../../../../utilities/lines'
import {
  calculateHighlightOffsetAndWidth,
  getActualLineNumberBinarySearch,
  queryLineElement,
} from '../../../../utilities/lines'
import {CopilotButton, type CopilotButtonHandle} from '../CopilotButton'
import {useHighlightedLinesRange} from './HighlightedLineProvider'
import type {CodeLineData} from './hooks/use-code-lines'

export function HighlightedLinesOverlay({
  linesData,
  copilotAccessAllowed,
}: {
  linesData: CodeLineData[]
  copilotAccessAllowed: boolean
}) {
  const range = useHighlightedLinesRange()
  const {tabSize} = useCurrentBlob()
  const lineHeight = useCurrentLineHeight('react-line-numbers')
  //on safari the highlights are weirdly offset from the text area highlights, so we add a -3px bump to them
  const isSafari = useIsSafari()

  const anchorRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<CopilotButtonHandle>(null)

  const startLine = range ? getActualLineNumberBinarySearch(range.start.line, linesData) : 0
  const endLine = range?.end.line ? getActualLineNumberBinarySearch(range.end.line, linesData) : startLine

  const [copilotButtonOffsetTop, setCopilotButtonOffsetTop] = useState(
    isSafari ? startLine * lineHeight - 3 : startLine * lineHeight,
  )
  useLayoutEffect(() => {
    if (!range) return
    menuRef.current?.setAnchor(anchorRef.current)
    setCopilotButtonOffsetTop(isSafari ? startLine * lineHeight - 3 : startLine * lineHeight)
  }, [range, isSafari, lineHeight, startLine])
  if (!range) return null

  const highlightPositions: Array<{position: HighlightPosition; lineNumber: number}> = []

  for (let lineNumber = startLine; lineNumber <= endLine; lineNumber++) {
    const element = queryLineElement('', lineNumber + 1)
    if (!element) continue

    const position =
      calculateHighlightOffsetAndWidth(
        range,
        element,
        lineNumber + 1,
        tabSize,
        linesData[lineNumber + 1]?.rawText ?? '',
      ) ?? {}

    highlightPositions.push({position, lineNumber})
  }

  return (
    <>
      <div ref={anchorRef} style={{position: 'relative', top: copilotButtonOffsetTop + 10}}>
        {highlightPositions.map(({position, lineNumber}) => (
          <LineHighlight
            key={`line-${lineNumber}-highlight-${position.offset}`}
            highlightPosition={position}
            lineNumber={lineNumber}
            startingLineNumber={startLine}
          />
        ))}
        {copilotAccessAllowed && (
          <CopilotButton
            ref={menuRef}
            rowBeginNumber={range.start.line}
            rowEndNumber={range.end.line}
            recalcPosition={copilotButtonOffsetTop}
            id="code-line-copilot-button"
          />
        )}
      </div>
    </>
  )
}

export function LineHighlight({
  startingLineNumber,
  lineNumber,
  highlightPosition,
  subtle,
}: {
  startingLineNumber: number
  lineNumber: number
  highlightPosition?: HighlightPosition
  subtle?: boolean
}) {
  const lineHeight = useCurrentLineHeight('react-line-numbers')
  const topOffset = (lineNumber - startingLineNumber) * lineHeight
  return (
    <Box
      className="line-highlight"
      sx={{
        position: 'absolute',
        backgroundColor: subtle ? 'neutral.subtle' : 'var(--bgColor-attention-muted, var(--color-attention-subtle))',
        height: lineHeight,
        opacity: '.6',
        boxShadow: subtle
          ? 'inset 2px 0 0 var(--borderColor-neutral-emphasis, var(--color-fg-subtle))'
          : 'inset 2px 0 0 var(--bgColor-attention-emphasis, var(--color-attention-fg))',
        left: highlightPosition && highlightPosition.offset ? `${highlightPosition.offset - 10}px` : `-82px`,
        //subtract 10 because we added 10 to have the copilot button line up properly
        top: `${topOffset - 10}px`,
        // Need to add 82px to account for the shifting to the left of the highlight
        width: highlightPosition && highlightPosition.width ? `${highlightPosition.width + 82}px` : `calc(100% + 82px)`,
        // Allow clicks to pass through this element to the underlying code line
        pointerEvents: 'none',
      }}
      key={`highlighted-line-${lineNumber}`}
    />
  )
}

try{ HighlightedLinesOverlay.displayName ||= 'HighlightedLinesOverlay' } catch {}
try{ LineHighlight.displayName ||= 'LineHighlight' } catch {}