import {DirectoryRichtextContent} from '@github-ui/code-view-shared/components/directory/DirectoryRichtextContent'
import {EditButton} from '@github-ui/code-view-shared/components/EditButton'
import TableOfContentsPanel from '@github-ui/code-view-shared/components/TableOfContentsPanel'
import {useFilesPageInfo} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'
import type {PanelType, ReadmeBlobPayload} from '@github-ui/code-view-types'
import {useCurrentRepository} from '@github-ui/current-repository'
import {repositoryTreePath} from '@github-ui/paths'
import {Box, Heading, Link} from '@primer/react'

import TableOfContents from '../../headers/header-components/TableOfContents'
import {Panel} from '../../Panel'

export function DirectoryReadmePreview({
  openPanel,
  readme,
  setOpenPanel,
  stickyHeaderHeight,
}: {
  openPanel: PanelType | undefined
  readme: ReadmeBlobPayload
  setOpenPanel: (panel: PanelType | undefined) => void
  stickyHeaderHeight?: number
}) {
  const {displayName, errorMessage, richText, headerInfo, timedOut} = readme
  const {toc} = headerInfo || {}
  const repo = useCurrentRepository()
  const {refInfo, path} = useFilesPageInfo()
  const readmePath = path && path !== '/' ? `${path}/${displayName}` : displayName

  return (
    <Box sx={{minWidth: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 3}}>
      <Box
        id="readme"
        sx={{
          borderColor: 'border.default',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 2,
          width: openPanel === 'toc' ? '65%' : '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            pr: 2,
            pl: 3,
            py: 2,
            borderBottom: '1px solid',
            borderColor: 'border.default',
          }}
        >
          <Heading as="h2" sx={{fontSize: 1, flexGrow: 1}}>
            <Link sx={{color: 'fg.default', '&:hover': {color: 'accent.fg'}}} href="#readme">
              {displayName}
            </Link>
          </Heading>
          {refInfo.canEdit && (
            <EditButton
              editPath={repositoryTreePath({repo, commitish: refInfo.name, action: 'edit', path: readmePath})}
              editTooltip="Edit README"
            />
          )}
          <TableOfContents toc={toc} openPanel={openPanel} setOpenPanel={setOpenPanel} isDirectoryReadme={true} />
        </Box>
        <DirectoryRichtextContent
          richText={richText}
          errorMessage={errorMessage}
          path={readmePath}
          stickyHeaderHeight={stickyHeaderHeight}
          timedOut={timedOut}
        />
      </Box>

      {openPanel === 'toc' && (
        <Panel sx={{height: 'fit-content', width: '35%'}}>
          <TableOfContentsPanel
            onClose={() => {
              setOpenPanel(undefined)
            }}
            toc={toc}
          />
        </Panel>
      )}
    </Box>
  )
}

try{ DirectoryReadmePreview.displayName ||= 'DirectoryReadmePreview' } catch {}